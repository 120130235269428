/* eslint-disable */
import LazyLoad from "vanilla-lazyload";
import inView from "in-view";

const HTML = document.querySelector("html");

class App {
  constructor() {
    this.lazyload = new LazyLoad({
      unobserve_entered: true,
      elements_selector: ".js-lazy",
      threshold: window.innerHeight * 0.8,
      // eslint-disable-next-line camelcase
      callback_enter: ($el) => {
        if ($el.classList.contains("js-lazy-error-placeholder")) {
          $el.lazyLoadPlaceholder = $el.src;
        } else if ($el.dataset.src == undefined) {
          const figure = $el.closest("figure");
          if (figure) figure.classList.add("img-is-loaded");
        }
      },
      callback_loaded: (el) => {
        const figure = el.closest("figure");
        if (figure) figure.classList.add("img-is-loaded");
      },
      // eslint-disable-next-line camelcase
      callback_error: ($el) => {
        if ($el.lazyLoadPlaceholder) {
          $el.src = $el.lazyLoadPlaceholder;
        }
      },
    });

    this.popin();
    this.inViewport();
  }

  popin() {
    const triggers = document.querySelectorAll(
      ".trigger-popin, .trigger-close"
    );

    if (triggers) {
      triggers.forEach((trigger) => {
        trigger.addEventListener("click", (e) => {
          e.preventDefault();

          HTML.classList.toggle("open-popin");
        });
      });
    }
  }

  inViewport() {
    // In viewport
    inView.offset({
      bottom: window.innerHeight * 0,
    });
    inView(".js-reveal:not(.is-visible)")
      .on("enter", function (elt) {
        elt.dispatchEvent(new Event("inview"));
        elt.classList.add("is-visible");
        elt.classList.remove("not-visible");

        const video = elt.querySelector("video");

        if (
          (elt.tagName == "VIDEO" && elt.dataset.autoplay == "true") ||
          (video && video.dataset.autoplay == "true")
        ) {
          const v = video || elt;
          const playPromise = v.play();
          if (playPromise !== null) {
            playPromise.catch(() => {
              v.play();
            });
          }
        }
      })
      .on("exit", function (elt) {
        const video = elt.querySelector("video");
        if (
          (elt.tagName == "VIDEO" && elt.dataset.autoplay != "") ||
          (video && video.dataset.autoplay != "")
        ) {
          const v = video || elt;
          v.pause();
        }

        elt.dispatchEvent(new Event("outview"));
        elt.classList.add("not-visible");
      });
  }
}

window.addEventListener("DOMContentLoaded", () => {
  new App();
});
